
<script>
import Banner from "@/components/banner";
import Tabbar from "@/components/Tabbar";
import NavBar from "@/components/JNav";
import { CellGroup, Cell } from "vant";
export default {
  components: {
    Banner,
    Tabbar,
    CellGroup,
    Cell,
    NavBar,
  },
};
</script>

<template>
  <div class="wrap">
    <NavBar :title="$t('agent.title.agent')" />
    <div class="banner_wrap">
      <Banner position="agent" />
    </div>
    <CellGroup class="group">
      <Cell is-link class="cell_item" to="/member">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont icon-chengyuanguanli" style="color: #68d079"></i>
            <span>{{ $t("agent.title.memberControl") }}</span>
          </div>
        </template>
      </Cell>
      <Cell is-link class="cell_item" to="/inviteFriends">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont icon-fenxianglianjie" style="color: #4eacf8"></i>
            <span>{{ $t("agent.title.share") }}</span>
          </div>
        </template>
      </Cell>
    </CellGroup>

    <CellGroup class="group">
      <Cell is-link class="cell_item">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont icon-anquanbaozhang" style="color: #1e4a70"></i>
            <span>{{ $t("agent.title.agentRead") }}</span>
          </div>
        </template>
      </Cell>
      <Cell is-link class="cell_item" to="/Cart">
        <template #title>
          <div class="left_wrap">
            <i
              class="iconfont icon-iconfontyouxihudong"
              style="color: #d9525e"
            ></i>
            <span>{{ $t("agent.title.betInfo") }}</span>
          </div>
        </template>
      </Cell>
      <Cell is-link class="cell_item" to="/AccountData">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont icon-yinhangqia1" style="color: #68d079"></i>
            <span>{{ $t("agent.title.paymentInfo") }}</span>
          </div>
        </template>
      </Cell>
    </CellGroup>

    <CellGroup class="group">
      <Cell is-link class="cell_item" to="/TeamReport">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont icon-baobiaofenxi" style="color: #05146e"></i>
            <span>{{ $t("agent.title.teamInfo") }}</span>
          </div>
        </template>
      </Cell>
      <!-- 每日 -->
      <!-- <Cell is-link class="cell_item">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont icon-baobiao3" style="color: #1E4A70"></i>
            <span>{{ $t("agent.title.daily") }}</span>
          </div>
        </template>
      </Cell> -->
      <Cell is-link class="cell_item" to="/AgentReport">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont icon-baobiao4" style="color: #f5c142"></i>
            <span>{{ $t("agent.title.agent") }}</span>
          </div>
        </template>
      </Cell>
      <Cell is-link class="cell_item" to="/Commission">
        <template #title>
          <div class="left_wrap">
            <i class="iconfont icon-icon3" style="color: #579af8"></i>
            <span>{{ $t("agent.title.agentCommission") }}</span>
          </div>
        </template>
      </Cell>
    </CellGroup>
    <!-- <Tabbar active="agent" palaceholderBackground="#f5f5f5" /> -->
  </div>
</template>

<style lang="less" scoped>
@import url("../../assets/iconfonts/iconfont.css");

@Color: var(--theme);
@comColor: var(--theme);
@wrapShadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: linear-gradient(#093958, #022c47);
@comBG: var(--bg1);
.wrap {
  min-height: 100vh;
  // background: #f5f5f5;
  background: var(--bg);

  .banner_wrap {
    padding: calc(16rem / 16);
  }

  .group {
    // margin-top: calc(12rem / 16);
    margin: @wrapPadding;
    border-radius: @comRadius;
    overflow: hidden;
    background: var(--bg);
    &::after {
      border: none;
    }

    .cell_item {
      // border-bottom: 1px solid #eee;
      background: @comBG;

      .left_wrap {
        display: flex;
        align-items: center;

        .iconfont {
          font-size: calc(26rem / 16);
          margin-right: calc(12rem / 16);
        }
        span {
          // color: var(--theme);
          color: var(--textColor);
        }
      }
    }
  }
}
</style>